








































































import Vue from "vue";
import Component from "vue-class-component";

import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";
import OperatorSearch from "@/components/layout/OperatorSearch.vue";

import { SearchViewModel } from "@/common/vms/SearchViewModel";
import { SearchPresenter } from "@/common/presenters/SearchPresenter";
import { SearchController } from "@/common/controllers/SearchController";

import { OperatorGraphQLGateway } from "@/gateways/graphql/OperatorGraphQLGateway";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { OperatorGraphQLService } from "../services/OperatorGraphQLService";

import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { PlantGraphQLService } from "@/plant/services/PlantGraphQLService";
import { Plant, PlantPart } from "../services/PlantService";
@Component<SearchOperatorView>({
  components: {
    Container,
    Column,
    Row,
    OperatorSearch,
    CreateOperatorView: () => import("@/plant/views/CreateOperatorView.vue")
  },
  props: {
    showOperatorSearch: {
      type: Boolean,
      default() {
        return true;
      }
    },
    plant: {}
  },
  watch: {
    visible() {
      this.$destroy();
    }
  }
})
export default class SearchOperatorView extends Vue {
  private plant!: any;
  private searchVm = new SearchViewModel();
  private components: any[] = [];
  private showOperatorSearch!: boolean;
  private visible = true;
  private selectedOperators: string[] = this.plant.operators.map(
    (op: any) => op.id
  );

  private connection = new AxiosGraphQLConnection(
    new AxiosLogger(new ConsoleLogger())
  );
  private plantService = new PlantGraphQLService(this.connection);

  private get searchText() {
    return this.$route.params.searchText;
  }

  private get searchController() {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new SearchController(
      new SearchPresenter(this.searchVm),
      new OperatorGraphQLService(connection)
    );
  }

  private mounted() {
    if (this.showOperatorSearch) {
      this.searchController.init(this.searchText);
    }
  }

  private onOperatorsSelected(event: any) {
    const index = this.selectedOperators.findIndex(el => el === event.item.id);
    if (index >= 0) {
      this.selectedOperators.splice(index, 1);
    } else {
      this.selectedOperators.push(event.item.id);
    }
  }

  private goToOperatorCreate() {
    this.components.push("CreateOperatorView");
  }

  private finished() {
    const request = this.plantService.updateOperators(
      this.plant,
      this.selectedOperators
    );
    FormRequestHandler.handle(
      request,
      response => {
        if (!response.loading && !response.error) {
          this.visible = false;
          this.$emit("finished");
        }
      },
      "update-plant-failed"
    );
  }
}
